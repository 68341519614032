import styled from 'styled-components';

export const Container = styled.button`
  border-style: none;
  border-radius: 12px;
  background: var(--highlightColor);
  color: var(--highlightText);
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;