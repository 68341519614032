import {
  Container,
  StyledClipLoader,
  Message
} from './styles';

interface LoaderProps {
  message1: string;
  message2: string;
}

export const Loader = ({ message1, message2 }: LoaderProps) => {
  return (
    <Container>
      <StyledClipLoader
        color='#6460AA'
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />

      <Message>{message1}</Message>
      
      <Message>{message2}</Message>
    </Container>
  );
};
